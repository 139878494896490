/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .outlined {
    @apply outline-dashed outline-black outline-offset-2 outline-2;
  }

  .outlined-white {
    @apply outline-dashed outline-white outline-offset-2 outline-2;
  }
}

/* The body tag in index.html gets written over by the Elm app */
body {
  @apply h-screen box-border overflow-x-hidden flex flex-col text-saint-patrick-blue;
  margin-right: calc(-1 * (100vw - 100%)); /* This stops content shifting when the scrollbar appears/disappears */
  scrollbar-color: theme(colors.portland-orange) theme(colors.white-lilac);
}

html {
  @apply overflow-y-auto;
}

body::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}

body::-webkit-scrollbar-thumb { /* Foreground */
  background: theme(colors.portland-orange);
}

body::-webkit-scrollbar-track { /* Background */
  background: theme(colors.white-lilac);
}

svg {
  width: 100%;
  height: 100%;
}

table {
  border-spacing: 0;
}

/* The option is there to move this to a tailwind plugin and auto-generate the
classes for the grid and padding styles later
*/

@layer utilities {
  .table-grid-p-0 {
    --table-grid-padding: 0;
  }

  .table-grid-p-4 {
    --table-grid-padding: 1rem;
  }

  .table-grid-p-8 {
    --table-grid-padding: 2rem;
  }
}

@layer components {
  .table-grid-2 {
    display: grid;
    grid-template-columns: var(--table-grid-padding) 1fr 1fr var(--table-grid-padding);
    justify-content: stretch;

    /* Reset all cells */
    & li:nth-child(n) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* First column */
    & li:nth-child(2n + 1) {
      padding-left: var(--table-grid-padding);
    }

    /* Last column */
    & li:nth-child(2n) {
      padding-right: var(--table-grid-padding);
    }

    /* First row */
    & li:nth-child(-n+2) {
      padding-top: var(--table-grid-padding);
    }
  }

  .table-grid-3 {
    display: grid;
    grid-template-columns: var(--table-grid-padding) 1fr 0 1fr 1fr var(--table-grid-padding);
    justify-content: stretch;

    /* Reset all cells */
    & li:nth-child(n) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* First column */
    & li:nth-child(3n + 1) {
      padding-left: var(--table-grid-padding);
    }

    /* Last column */
    & li:nth-child(3n) {
      padding-right: var(--table-grid-padding);
    }

    /* First row */
    & li:nth-child(-n+3) {
      padding-top: var(--table-grid-padding);
    }
  }

  .table-grid-4 {
    display: grid;
    grid-template-columns: var(--table-grid-padding) 1fr repeat(2, 0 1fr) 1fr var(--table-grid-padding);
    justify-content: stretch;

    /* Reset all cells */
    & li:nth-child(n) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* First column */
    & li:nth-child(4n + 1) {
      padding-left: var(--table-grid-padding);
    }

    /* Last column */
    & li:nth-child(4n) {
      padding-right: var(--table-grid-padding);
    }

    /* First row */
    & li:nth-child(-n+4) {
      padding-top: var(--table-grid-padding);
    }
  }

  .table-grid-5 {
    display: grid;
    grid-template-columns: var(--table-grid-padding) 1fr repeat(3, 0 1fr) 1fr var(--table-grid-padding);
    justify-content: stretch;

    /* Reset all cells */
    & li:nth-child(n) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* First column */
    & li:nth-child(5n + 1) {
      padding-left: var(--table-grid-padding);
    }

    /* Last column */
    & li:nth-child(5n) {
      padding-right: var(--table-grid-padding);
    }

    /* First row */
    & li:nth-child(-n+5) {
      padding-top: var(--table-grid-padding);
    }
  }

  .table-grid-6 {
    display: grid;
    grid-template-columns: var(--table-grid-padding) 1fr repeat(4, 0 1fr) 1fr var(--table-grid-padding);
    justify-content: stretch;

    /* Reset all cells */
    & li:nth-child(n) {
      padding-top: 0;
      padding-right: 0;
      padding-left: 0;
    }

    /* First column */
    & li:nth-child(6n + 1) {
      padding-left: var(--table-grid-padding);
    }

    /* Last column */
    & li:nth-child(6n) {
      padding-right: var(--table-grid-padding);
    }

    /* First row */
    & li:nth-child(-n+6) {
      padding-top: var(--table-grid-padding);
    }
  }
}
